<template>
  <div>
    <ui-page-hero id="id_title_channel" :title="'Invoices'" />

    <Invoices />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'

const Invoices = () => import('@/components/FeeInvoices/Invoices')

export default {
  components: {
    Invoices,
  },

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  mounted() {},

  beforeDestroy() {},

  methods: {},
}
</script>
